const h4Styles = { 
  letterSpacing: '0.25rem',
}

const pStyles = {
  letterSpacing: '0.25rem',
  transform: 'translate(0, -0.5rem)',
}

const addBrand = () => {
  return (
    <>
    <p className={`fontPrompt fs-1 dropShadow`} style={h4Styles}>STANLEY</p>
    <p className={`fontPrompt text-white`} style={pStyles}>COOLING CORP</p>
    </>
  )
}

export default addBrand;