import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { BiPhoneOutgoing } from 'react-icons/bi';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BsArrowDownCircle } from 'react-icons/bs';
import { FaShuttleVan } from 'react-icons/fa';
import Form from '../../components/Form/Form'
import RowTextOnly from '../../components/Rows/RowTextOnly/RowTextOnly';
import addBrand from '../../utils/addBrand';
import styles from './Contact.module.css';

const Contact = ({ phone, urlCalendly, urlFinance, urlMap }) => {
  return (
    <div id='contact' className={`${styles.contactWrapper} text-center`} role='form'>
      <div className={`${styles.h2Wrapper}`}>
        <h2 className={`${styles.h2} fontPrompt text-white dropShadowBig`}>CONTACT US</h2>
      </div>
      <div className={`${styles.bg} bg bgClip text-white`}>
        <div className={`${styles.bar} text-end`}>
          {addBrand()}
        </div>
        <motion.div
          className={`${styles.bgWrapper}`}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <RowTextOnly
            heading={[
              <FaShuttleVan size={40} className={`${styles.van} dropShadow`} />,
              <br />, <br />, `We're on our way!`
            ]}
            text={[
              <span className={`fw-normal fontPrompt`}>STANLEY Cooling Corp</span>,
              ` is committed to providing expert HVAC repair and installation.  Call us today, we look forward to serving your HVAC needs.`
            ]}
            textStyles={`fs-6 fw-normal text-black`}
          />
          <Link
            tabIndex="0"
            role="button"
            to='form'
            duration={500}
          >
            <BsArrowDownCircle className={`${styles.arrow}`} size={30} color={'orange'} />
          </Link>
        </motion.div>
      </div>
      <div id='form' className={`${styles.contact} pt-5 text-center`}>
        <h3 className={`${styles.h3} pt-5 fw-light mx-auto fontPrompt`}>CONTACT HVAC EXPERTS</h3>
        <a
          href={`tel:${phone}`}
          className={`${styles.link} mt-5 fs-4 textGray`}
          tabIndex="0"
          role="button"
        >
          <div className={`${styles.icon} boxShadowDeep`}>
            <BiPhoneOutgoing color={'ghostwhite'} size={25} />
          </div>
          <p className={`${styles.p}`}>
            {phone}
          </p>
        </a>
        <p className={`fw-bolder`}>Text us for emergency service</p>
        <a
          className={`${styles.calendly}`}
          href={urlCalendly}
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaRegCalendarAlt />
          <p>
            Schedule now on Calendly
          </p>
        </a>
        <h5 className='fw-light'>Send a message:</h5>
        <Form />
      </div>
      <iframe
        src={urlMap}
        className={`${styles.iframe}`} style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title='Google map of Cape Coral, Forida.'
      />
    </div>
  )
}

export default Contact;
