import { motion } from 'framer-motion';
import { BsThermometerSnow } from 'react-icons/bs';
import { FaHeadset, FaTools, FaRegThumbsUp } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';

import ButtonContact from '../../components/ButtonContact/ButtonContact';
import RowIcons from '../../components/Rows/RowIcons/RowIcons';
import RowTextOnly from '../../components/Rows/RowTextOnly/RowTextOnly';
import addBrand from '../../utils/addBrand';
import render5Stars from '../../utils/render5Stars';
import spanMap from '../../utils/spanMap';

import styles from './About.module.css';

const About = ({address, hours, urlReviews}) => {
  return (
    <div id='about' className={`${styles.about} container-fluid`} role='main'>
      <div className={`${styles.h2Wrapper}`}>
        <h2 className={`${styles.h2} fontPrompt text-white dropShadowBig`}>OUR MISSION</h2>
      </div>
      <div className={`${styles.bg} bg bgClip text-white`}>
        <div className={`${styles.bar} text-end`}>
          {addBrand()}
        </div>
        <motion.div
          className={`${styles.bgWrapper} text-black`}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <RowTextOnly
            heading={[
              <FaTools size={40} color={`gold`} className={'dropShadow'} />,
              <br/>, <br/>, `Expert Repair and Installation`
            ]}
            text={[
              <span className={`fw-normal fontPrompt`}>STANLEY Cooling Corp</span>,
              ` is committed to providing expert HVAC repair and installation.  With over a decade of experience `,
              <span className={`fw-normal fontPrompt`}>STANLEY</span>,
              ` is proudly serving the southwest Florida community with hundreds of satisfied customers.`
            ]}
            textStyles={`fs-6 fw-normal`}
          />
        </motion.div>
      </div>

      <RowIcons
        icon1={{
          icon: <BsThermometerSnow color={'cornflowerblue'} size={40} />,
          heading: [`Heating and Cooling Professionals`],
          text: [
            <span className={`fw-normal fontPrompt`}>STANLEY</span>,
            ` is an experienced professional who uses the best parts and hires trained technicians.`
          ],
        }}
        icon2={{
          icon: <FaHeadset color={'red'} size={40} />,
          heading: [`Reliable Service`],
          text: [
            `Are you having HVAC problems? Call now, `,
            <span className={`fw-normal fontPrompt`}>STANLEY</span>,
            ` will give you a free estimate for a fair price.`
          ],
        }}
        icon3={{
          icon: <FaRegThumbsUp color={'mediumseagreen'} size={40} />,
          heading: [`Satisfaction Guaranteed`],
          text: [
            <span className={`fw-normal fontPrompt`}>STANLEY</span>,
            ` will resolve your HVAC issues, that's a guarantee. All parts and labor are also 100% guaranteed!`
          ],
        }}
      />
      
      <div className={`row fw-light`}>
        <div className={`${styles.col} col-sm-6`}>
          <h4 className={`text-center fw-light py-5`}>
            { spanMap([
                `See our 5-star reviews on `,
                <a href={urlReviews} target='_blank' rel='noreferrer'>Google</a>,
                `!`, <br/>, render5Stars()
              ]) }
          </h4>
        </div>

        <div className={`${styles.col} text-center col-sm-6 my-auto py-4`}>
          <p><FiClock size={25} /> Hours: {hours}<br/>Weekends by Appointment</p>
          <p><MdOutlineLocationOn size={30} /> {address.street}<br/>{address.cityState}</p>
        </div>
      </div>
      
      <div className={`${styles.buttonWrapper} fontPrompt py-5`}>
        <p className={`fs-2 fw-light text-center py-5 mt-5`}>CONTACT</p>
        <ButtonContact text={'HVAC Experts'} style={{fontWeight: '300'}} />
      </div>

      <div className={`${styles.bg} bgImg text-white`}>
        <div className={`${styles.rowWrapper}`}>
          <RowTextOnly
            heading={[<span className={`fs-1 fw-bold`}>
              CITIES WE SERVICE  
            </span>]}
            text={[
              <ul className={`${styles.cities} fs-2 fw-light`}>
                <li>BONITA SPRINGS </li>
                <li>LEHIGH ACRES</li>
                <li>CAPE CORAL</li>
                <li>NORTH FORT MYERS</li>
                <li>PUNTA GORDA</li>
                <li>FORT MYERS</li>
                <li>PINE ISLAND</li>
                <li>MATLACHA</li>
                <li>NAPLES</li>
                <li>SAINT JAMES CITY</li>
              </ul>
            ]}
            textStyles={`fs-5`}
          />
          <div className={`${styles.buttonWrapper} fontPrompt text-center`}>
            <ButtonContact text={'Schedule Service'} style={{fontWeight: '200', marginTop: '3rem'}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About