import ButtonContact from '../ButtonContact/ButtonContact';
import logo from '../../assets/images/stanleyLogo.gif';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div id='header' className={`${styles.headerWrapper} text-white textShadow`} role='banner'>
      <div className={`${styles.header}`}>
        <div className={`${styles.layer}`}></div>
        <img src={logo} alt='Stanley Cooling Corporation logo.' className={`${styles.logo} img-fluid`} />
        <div className={`${styles.text}`}>
          <h1 className={`${styles.h1} fontPrompt`}>
            <span className={`dropShadow`}>STANLEY</span>
            <div className={`${styles.span}`}>COOLING CORP</div>
          </h1>
          <h2 className={`${styles.h2}`}>
            &#x2022; Expert HVAC Repair &#x2022; Free Estimate &#x2022; Affordable Financing &#x2022;
          </h2>
          <ButtonContact text={'Contact Us'} style={{textShadow:'1px 1px 1px black'}} />
          <h3 className={`${styles.h3} text-end dropShadowBig`}>Proudly serving SWFL with over a decade of professional HVAC experience.</h3>
        </div>
      </div>
    </div>
  )
}

export default Header;
