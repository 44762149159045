import { Link } from 'react-scroll'

const scrollHandler = (id, text) => {
  return (
    <Link
      tabIndex="0"
      role="button"
      to={id}
      duration={750}
    >
    {text}
  </Link>
  )
}

export default scrollHandler;
