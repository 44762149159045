import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha';

import checkEmail from '../../utils/checkEmail';
import useWindowDimensions from '../../utils/windowDimensions';
import styles from './Form.module.css';

const FormInput = ({ label, type, placeholder, name, value, onChange, onBlur }) => {
  return (
    <div className={`${styles.row} row`}>
      <label className={`${styles.label} col-lg-2`} htmlFor={name}>{label}</label>
      <input
        id={name}
        className={`${styles.input} col-lg-10`}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

const Form = () => {
  const recaptchaRef = useRef(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [disable, setDisable] = useState(true)
  const [token, setToken] = useState('');
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    property: '',
    message: '',
  });
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (values.name && values.message && values.email && values.phone && values.address && values.property && checkEmail(values.email) === true) {
      setDisable(false);
      return
    }
  }, [values])

  const handleInput = (e) => {
    e.persist();
    if (e.target.name === 'name') {
      setValues((values) => ({
        ...values,
        name: e.target.value,
      }));
    }
    if (e.target.name === 'email') {
      setValues((values) => ({
        ...values,
        email: e.target.value,
      }));
      if (!checkEmail(e.target.value)) {
        setDisable(true);
      }
    }
    if (e.target.name === 'phone') {
      setValues((values) => ({
        ...values,
        phone: e.target.value,
      }));
    }
    if (e.target.name === 'address') {
      setValues((values) => ({
        ...values,
        address: e.target.value,
      }));
    }
    if (e.target.name === 'property') {
      setValues((values) => ({
        ...values,
        property: e.target.value,
      }));
    }
    if (e.target.name === 'message') {
      setValues((values) => ({
        ...values,
        message: e.target.value,
      }));
    }
    if (!e.target.value && !e.target.name === 'phone') {
      setDisable(true);
    }
    if (e.target.value && e.target.type === 'email' && checkEmail(values.email) === true) {
      setInvalidEmail(false)
    }
  }

  const onBlur = (e) => {
    if (!e.target.value && e.target.type === 'email') {
      setDisable(true);
    } else if (!e.target.value && e.target.type !== 'email') {
      setDisable(true);
    }
    if (e.target.value && e.target.type === 'email' && checkEmail(values.email) === false) {
      setDisable(true);
      setInvalidEmail(true);
    }
    if (e.target.value && e.target.type === 'email' && checkEmail(values.email) === true) {
      setInvalidEmail(false);
    }
  }

  const clearValues = () => {
    setValues({
      name: '',
      email: '',
      phone: '',
      address: '',
      property: '',
      message: '',
    })
  }

  const clickHandler = (e) => {
    e.preventDefault();
    if (disable) {
      if (values.email && checkEmail(values.email) === false) {
        toast.error('Invalid email address');
        return;
      }
      toast.error('Please complete all required fields');
      return;
    };
    if (!token || token === '') {
      toast.error(`Please verify you're not a robot, reload page if recaptcha is missing`);
      return;
    };

    if (!disable && token && token !== '') {
      toast.info('Thank you for the message! Confirmation incoming...');
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 250);
      recaptchaRef.current.reset();
      setToken('')
      setDisable(true);

      // API call
      fetch("https://formsubmit.co/ajax/f61adf2ff38bc5c4deb30cb261bf1ec0", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          phone: values.phone,
          address: values.address,
          property: values.property,
          message: values.message,
          subject: 'STANLEY COOLING CORP',
        })
      })
        .then(response => response.json())
        .then(data => {
          setTimeout(() => toast.info(data.message), 500);
          clearValues();
        })
        .catch(error => toast.error(error.message));
    }
  }

  const recaptchaHandler = () => {
    setToken(recaptchaRef.current.getValue());
  }

  return (
    <form className={`${styles.form} mx-auto container-fluid`}>
      <FormInput
        label={'Name: '}
        type={'text'}
        placeholder={'Enter your name (required)'}
        name={'name'}
        value={values.name}
        onChange={handleInput}
        onBlur={onBlur}
      />
      <div className={`${styles.row} row`}>
        <label className={`${styles.label} col-lg-2`} htmlFor="email">Email:</label>
        <input
          id='email'
          className={`${styles.input} col-lg-10`}
          type="email"
          placeholder="Email@domain.com (required)"
          name="email"
          value={values.email}
          onChange={handleInput}
          onBlur={onBlur}
        />
        {
          (values.email && invalidEmail) &&
          <div className={`${styles.invalid} text-end`}>
            Invalid Email
          </div>
        }
      </div>
      <FormInput
        label={'Phone: '}
        type={'number'}
        placeholder={'xxx-xxx-xxxx (required)'}
        name={'phone'}
        value={values.phone}
        onChange={handleInput}
        onBlur={onBlur}
      />
      <FormInput
        label={'Address: '}
        type={'text'}
        placeholder={'Street, city, zip code (required)'}
        name={'address'}
        value={values.address}
        onChange={handleInput}
        onBlur={onBlur}
      />
      <div className={`${styles.property}`} onChange={handleInput}>
        <label className={`${styles.label}`} htmlFor="property">Are you the property owner (required)?</label>
        <input type="radio" value="Tenant" name="property" checked={values.property === "Tenant"} /> Tenant
        <input type="radio" value="Owner" name="property" checked={values.property === "Owner"} /> Owner
      </div>
      <div className={`${styles.row} row`}>
        <label className={`${styles.label} col-lg-2`} htmlFor="">Message:</label>
        <textarea
          className={`${styles.textarea} col-lg-10`}
          name="message"
          cols="30"
          rows="3"
          placeholder="How can we help? (required)"
          value={values.message}
          onChange={handleInput}
          onBlur={onBlur}
        />
      </div>
      <div className={`${styles.recaptchaWrapper} d-flex justify-content-center mt-2`}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_SITE_KEY}
          size={width >= 375 ? 'normal' : 'compact'}
          onChange={recaptchaHandler}
        />
      </div>
      <div className="text-center">
        <button
          className={`
            ${styles.button} boxShadowDeep textShadow
            ${disable || !token || token === '' ? styles.disable : null}
          `}
          onClick={(e) => clickHandler(e)}
          tabIndex="0"
          id="submit"
        >
          {disable || !token || token === '' ? 'Complete Form' : 'Send Message'}
        </button>
      </div>
    </form>
  )
}

export default Form;