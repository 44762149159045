import { motion } from 'framer-motion';
import spanMap from '../../../utils/spanMap';
import styles from './RowIcons.module.css';

const injectMotionIcon = (icon) => {
  return (
    <motion.div
      className={`${styles.col} col-sm-6 col-md-4 py-5`}
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}
    >
      {icon.icon}
      <h3>
        { spanMap(icon.heading) }
      </h3>
      <p className='fw-light'>
        { spanMap(icon.text) }
      </p>
    </motion.div>
  )
}

const RowIcons = ({icon1, icon2, icon3}) => {
  return (
    <div className={`${styles.row} row text-center my-5`}>
      {icon1 && injectMotionIcon(icon1)}
      {icon2 && injectMotionIcon(icon2)}
      {icon3 && injectMotionIcon(icon3)}
    </div>
  )
}

export default RowIcons