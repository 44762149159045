import { motion } from 'framer-motion';
import { Link } from 'react-scroll'
import styles from './ButtonContact.module.css';

const ButtonContact = ({style, text}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}
    >
      <Link
        className={`${styles.button} boxShadowDeep`}
        tabIndex="0"
        role="button"
        to='form'
        style={style}
        spy duration={750}
        
      >
        {text}
      </Link>
    </motion.div>
  )
}

export default ButtonContact