import { AnimatePresence, motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify'
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import NavBar from '../components/NavBar/NavBar';
import About from '../pages/About/About';
import Services from '../pages/Services/Services';
import Contact from '../pages/Contact/Contact';

import '../styles/background.css';
import '../styles/global.css';
import '../styles/typography.css';

const address = {
  street: `3721 Santa Barbara Blvd`,
  cityState: `Cape Coral, FL 33914`
}
const hours = `Mon-Fri, 8:30am-4pm`;
const phone = `239-232-8538`;
const email = `jenny@stanleycooling.com`;
const urlCalendly = `https://calendly.com/stanley-cooling-corp`;
const urlFinance= `https://www.mysynchrony.com/mmc/M9222653500`;
const urlMap = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228225.891870223!2d-82.13614757234834!3d26.647535964537845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db44a7e78016f5%3A0xafd1a4163a9b6ff2!2sCape%20Coral%2C%20FL!5e0!3m2!1sen!2sus!4v1663089007964!5m2!1sen!2sus`;
const urlReviews = `https://www.google.com/search?q=stanley+cooling+corp+florida+google+reviews`;

function App() {
  return (
    <AnimatePresence initial={true} mode={'wait'}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <NavBar />
        <Header />
        <About address={address} hours={hours} urlReviews={urlReviews} />
        <Services urlFinance={urlFinance} />
        <Contact phone={phone} urlCalendly={urlCalendly} urlFinance={urlFinance} urlMap={urlMap} />
        <ToastContainer />
        <Footer address={address} hours={hours} phone={phone} email={email} urlFinance={urlFinance} urlReviews={urlReviews} />
      </motion.div>
    </AnimatePresence>
  );
}

export default App;
