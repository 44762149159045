import { motion } from 'framer-motion';
import { Link } from 'react-scroll'
import styles from './NavBar.module.css';

const NavBar = () => {
  return (
    <motion.div
      className={`${styles.navWrapper} fontPrompt`}
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <nav className={`${styles.nav}`}>
        <ul className={`${styles.ul}`}>
          <li className={`${styles.li}`}>
            <Link className={`${styles.link} ${styles.s}`} activeClass={styles.active} spy duration={750} to="header">
              S<span className={`${styles.tanley}`}>TANLEY</span>
            </Link>
          </li>
          <li className={`${styles.li}`}>
            <Link className={`${styles.link}`} activeClass={styles.active} spy duration={750} to="about">
              About
            </Link>
          </li>
          <li className={`${styles.li}`}>
            <Link className={`${styles.link}`} activeClass={styles.active} spy duration={750} to="services">
              Services
            </Link>
          </li>
          <li className={`${styles.li}`}>
            <Link className={`${styles.link}`} activeClass={styles.active} spy duration={750} to="form">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </motion.div>
  )
}

export default NavBar;