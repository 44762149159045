import { motion } from 'framer-motion';
import { AiOutlineTool } from 'react-icons/ai';
import { BiDollarCircle } from 'react-icons/bi';
import { BsSpeedometer2 } from 'react-icons/bs';
import { MdAir } from 'react-icons/md';
import { FaFan } from 'react-icons/fa';
import { GiPalmTree } from 'react-icons/gi';
import { MdOutlineEngineering } from 'react-icons/md';
import { RiCommunityLine } from 'react-icons/ri';

import RowIcons from '../../components/Rows/RowIcons/RowIcons';
import RowSingleImg from '../../components/Rows/RowSingleImg/RowSingleImg';
import RowTextOnly from '../../components/Rows/RowTextOnly/RowTextOnly';
import ScrollTainer from '../../components/ScrollTainer/ScrollTainer';
import addBrand from '../../utils/addBrand';
import comfort from '../../assets/images/comfortable.jpg'
import finance from '../../assets/images/finance.gif'

import styles from './Services.module.css';

const Services = ({ urlFinance }) => {
  return (
    <div id='services' className={`${styles.about} py-5 container-fluid`} role='complementary'>
      <div className={`${styles.h2Wrapper}`}>
        <h2 className={`${styles.h2} fontPrompt text-white dropShadowBig`}>SERVICES</h2>
      </div>
      <div className={`${styles.bg} bg bgClip text-white`}>
        <div className={`${styles.bar} text-end`}>
          {addBrand()}
        </div>
        <motion.div
          className={`${styles.bgWrapper}`}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <RowSingleImg
            img={comfort}
            alt={`A very satisfied customer who is relaxing in their air conditioned home.`}
            heading={[`Get comfortable now!`]}
            headingStyles={`fs-1 fw-light textShadow pb-5 mb-5`}
            text={[`Do you need `,
              <span className={`fw-bold`}>HVAC</span>,
              ` service or a new installation?  Call `,
              <span className={`fw-bold fontPrompts`}>STANLEY</span>,
              ` now, let our experts give you a `,
              <span className={`fw-bold`}>FREE</span>,
              ` estimate and a `,
              <span className={`fw-bold`}>FAIR</span>,
              ` price.`]}
            textStyles={`fw-normal fs-6 text-black`}
          />
        </motion.div>
      </div>
      <div className={`${styles.iconWrapper}`}>
        <RowIcons
          icon1={{
            icon: <FaFan size={40} className={`${styles.icon} ${styles.fan}`} />,
            heading: [`Air Conditioning Service`],
            text: [
              <span className={`fontPrompt fw-normal`}>STANLEY</span>,
              ` offers the highest quality heating, ventilation, and air conditioning services in Southwest Florida.`
            ],
          }}
          icon2={{
            icon: <RiCommunityLine size={40} className={`${styles.icon} ${styles.house}`} />,
            heading: [`Installations`],
            text: [
              `Whether it's a small repair, or an entirely new installation, call `,
              <span className={`fontPrompt fw-normal`}>STANLEY</span>,
              ` to make sure that it's done right, no matter the size of the job.`
            ],
          }}
          icon3={{
            icon: <AiOutlineTool size={40} className={`${styles.icon} ${styles.wrench}`} />,
            heading: [`Preventive Maintenance`],
            text: [
              `Being proactive on your maintenance prevents unexpected failures to maximize the longevity of the unit. Our maintenance programs will save you money.`
            ],
          }}
        />
      </div>

      <ScrollTainer />

      <div className={`${styles.iconWrapper}`}>
        <RowIcons
          icon1={{
            icon: <MdOutlineEngineering size={40} className={`${styles.icon} ${styles.tech}`} />,
            heading: [`Qualified Technicians`],
            text: [
              <span className={`fontPrompt fw-normal`}>STANLEY</span>,
              ` hires quality technicians who are well-trained, courteous, and respectful of your beautiful home.`
            ],
          }}
          icon2={{
            icon: <MdAir size={40} className={`${styles.icon} ${styles.meter}`} />,
            heading: [`Air Quality`],
            text: [
              <span className={`fontPrompt fw-normal`}>STANLEY</span>,
              ` can improve the efficiency and quality in your home by ensuring your HVAC system is balanced and up to code. Ask about our air purification systems.`
            ],
          }}
        />
      </div>

      <div>
        {urlFinance &&
        <>
        <RowSingleImg
          img={finance}
          alt='STANLEY Cooling Corp is budget minded and offers affordable financing to all their customers.'
          imgStyles={{ maxWidth: `50vw`, maxHeight: `75vh` }}
          heading={[`Affordable Financing`]}
          headingStyles={``}
          text={[
            <ul className={`${styles.financeInfo} fw-normal`}> 
              <li>
                Monthly payments are calculated by dividing the purchase amount by the number of months in the promotional period. 
              </li>
              <li>
                 With on time payments, the purchase will be paid off by the end of the promotional period. 
              </li>
            </ul>
          ]}
          textStyles={`fw-normal fs-6 pb-5`}
        />
        <div className={`text-center my-5`}>
          <a href={urlFinance} target='_blank' rel='noreferrer' className={`${styles.finance} fw-normal boxShadowDeep`}>Financing Options</a>
        </div>
        </>}
      </div>

      <div className={`${styles.bg} bg text-black mt-5`}>
        <RowTextOnly
          heading={[
            <GiPalmTree size={40} className={`dropShadow`} />,
            <br />, <br />,
            `We support our community.`
          ]}
          text={[
            `Call `,
            <span className={`fw-bold`}>TODAY</span>,
            ` and ask about our senior discount.`
          ]}
          textStyles={`fs-6 fw-normal`}
        />
      </div>
    </div>
  )
}

export default Services