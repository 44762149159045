import { BsStarFill } from 'react-icons/bs';

const render5Stars = () => {
  const stars = [];
  for (let i = 0; i <= 5; i++) {
    stars.push(<BsStarFill key={i} className='dropShadow' color='gold' size={20} />);
  }
  return (
    stars
  )
}

export default render5Stars;
