import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FiClock } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import addBrand from '../../utils/addBrand';
import scrollHandler from '../../utils/scrollHandler';
import logo from '../../assets/images/pharma2code.gif';
import styles from './Footer.module.css';

export default function Footer({address, hours, phone, email, urlFinance, urlReviews}) {
  const currentYear = new Date().getFullYear();

  return (
    <div className={`${styles.divWrapper}`} role='contentinfo'>
      <div className={`${styles.div} text-center text-white`}>
        <div className={`${styles.row} row container-fluid fw-light`}>
          <div className={`${styles.colLeft} col-sm-6 text-start`}>
            {scrollHandler(`header`, addBrand())}
            {scrollHandler(`about`, `About Us`)}
            {scrollHandler(`services`, `Services`)}
            {scrollHandler(`form`, `Contact Us`)}
            <a href={urlReviews} target='_blank' rel='noreferrer'>Google Reviews</a>
            {urlFinance && <a href={urlFinance} target='_blank' rel='noreferrer'>Apply for Financing</a>}
          </div>
          <div className={`${styles.colRight} col-sm-6 text-end`}>
            <p><FiClock size={25} /> Hours: {hours}<br/>Weekends by Appointment</p>
            <p><MdOutlineLocationOn size={30} /> {address.street}<br/>{address.cityState}</p>
            <p><AiOutlinePhone size={30} /> {phone}</p>
            <p><AiOutlineMail size={30} /> {email}</p>
          </div>
        </div>
        <p className={`fs-6 fw-light text-white my-5 pb-3`}>Copyright © {currentYear} <span className={`fontPrompt`}>STANLEY Cooling Corp</span></p>
        <hr />
        <footer className={`${styles.footer} fw-lighter`}>
          Website developed by 
          <a
            href='https://www.codebypete.com'
            target='_blank'
            rel='noreferrer'
            className={`${styles.link} text-white pb-3`}
          >
            <img
              src={logo}
              className={`${styles.img}`}
              style={{ height: '2rem', margin: '0 0.25rem 0.25rem 0.5rem' }}
              alt="codeByPete logo"
            />
            <span className='fst-italic textShadow'>
              <span style={{ color: "white" }}> code</span>
              <span style={{ color: "#00857c" }}>By</span>
              <span style={{ color: "lime" }}>Pete</span>
            </span>
          </a>
        </footer>
      </div>
    </div>
  )
};